// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use './app/styles/m3-theme.scss' as theme;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

@tailwind base;
@tailwind components;
@tailwind utilities;

// Import required fonts: 
// Open Sans
// Raleway
// Roboto Condensed
// Roboto
// Source Sans Pro
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Roboto:wght@100;300;400;500;700;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
html {
  @include mat.all-component-themes(theme.$portal-theme);
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

//Fix an issue with conflicting styles between Tailwind and Angular Material
.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 8px;
}

//Global override for angular material variables
* {
  //table border colour
  --mat-table-row-item-outline-color: #E9E9E9;

  //Custom underline + focus colours for tabs
  --mdc-tab-indicator-active-indicator-color: #017971;
  --mat-tab-header-active-focus-indicator-color: #108f86;
  --mat-tab-header-active-hover-indicator-color: #108f86;

  //Custom colours for paginator arrow buttons
  --mat-paginator-enabled-icon-color: #105D93;
  --mat-paginator-disabled-icon-color: #9fbbcf;

  //Custom colours for date picker
  --mat-datepicker-calendar-container-background-color: #ffff;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --mdc-icon-button-icon-color: #105D93;
}

// force the density to be 0 to ensure global app styling does not override
.signup-form {
  @include mat.all-component-densities(0);
}